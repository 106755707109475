/* General message styles */
.error_msg,
.success_msg {
  outline: none;
  border: none;
  width: 100%;
  padding: 15px;
  border-radius: 10px;
  height: 47px;
  font-size: 14px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.error_msg {
  background-color: #f34646; /* Red background for errors */
}

.success_msg {
  background-color: green; /* Green background for success */
}

/* Class to make the message visible */
.visible {
  opacity: 1;
  transition: opacity 0.5s ease-out;
}

/* You can also add a hidden class if you prefer to toggle display */
.hidden {
  display: none;
}
