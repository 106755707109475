/* @import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap"); */

/* -------------------------------------- Globals -------------------------------------- */
/* ::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background: #efefef;
}
::-webkit-scrollbar-thumb {
  background: rgb(170, 170, 170);
}
::-webkit-scrollbar-thumb:hover {
  background: rgb(124, 124, 124);
} */
/* body {
  margin: 0;
  overflow-x: hidden;
  background-size: cover;
  background: radial-gradient(#cde3ff, #5da5ff);
}*/
h1,
h2,
h3,
h4,
h5,
h6, 
span,
p,
button {
  margin: 0;
  font-family: "Oswald";
  text-transform: uppercase;
}
.App {
  font-family: "Oswald";
  text-align: center;
  position: relative;
}
/* -------------------------------------- Navbar -------------------------------------- */
.navBar {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  z-index: 3;
}
.logo {
  width: 180px;
  padding: 10px;
}
.navLink {
  width: 225px;
  height: 35px;
  background: #efefef;
  border-radius: 8px;
  text-transform: uppercase;
  font-size: 17px;
  cursor: pointer;
  margin: 0 50px;
  border: none;
  font-family: "Oswald";
}
.navLink:hover {
  margin-top: -5px;
}
/* -------------------------------------- Header Section -------------------------------------- */
.background{
  background: radial-gradient(#0b2b55, #000308);
  /* overflow: "hidden"; */
}
.monkey {
  position: absolute;
  background-image: url('../../../public/static/images/login/ape.gif');
  box-shadow: 3000px 3000px 3000px inset #0d141ae8;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  /* height: 15%; */
  z-index: 2;
}
.header {
  position: relative;
  z-index: 2;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.headerBody {
  text-align: left;
  width: 66%;
  margin: 0 auto;
}
.headerBody h1 {
  color: #efefef;
  font-weight: 800;
  font-size: 73px;
  line-height: 73px;
  text-shadow: 0 0 10px #00000075;
  margin: 30px 0;
  font-family: "Oswald";
}
.headerBody h2 {
  color: #adadad;
  font-weight: 300;
  font-size: 30px;
  line-height: 40px;
  text-shadow: 0 0 10px #00000075;
  margin: 30px 0;
  font-family: "Oswald";
}
.socials {
  display: flex;
  column-gap: 20px;
  margin: 30px 0;
}
.socials img {
  width: 40px;
}
.socials img:hover {
  margin-top: -5px;
}
.body {
  background: #fff#efefef;
}

/* -------------------------------------- Storyline Section -------------------------------------- */
.storyline {
  padding: 75px 0;
}
.storyline .title {
  padding: 50px;
  padding: 0 0 50px;
}
.storyline .section {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 66%;
  margin: auto;
}
.storyline .section div {
  width: 50%;
}
.storyline .section h1 {
  font-size: 14px;
}
.right {
  width: 400px;
  border-radius: 15px;
  box-shadow: 0 0 10px #fff;
  border: 2px solid rgb(0, 0, 0);
  float: left;
  margin: -1em 0 -1em -2em;
}
.rightExtra {
  height: 25rem;
  border-radius: 15px;
  box-shadow: 0 0 10px #fff;
  border: 2px solid rgb(0, 0, 0);
  float: left;
  margin: -1em 0 -1em -1em;
}
.left {
  width: 400px;
  border-radius: 15px;
  box-shadow: 0 0 10px #fff;
  border: 2px solid rgb(0, 0, 0);
  float: right;
  margin: -1em -2em -1em 0;
}
.rightText {
  padding-left: 70px;
  text-align: left;
}
.leftText {
  padding-right: 70px;
  text-align: right;
}
/* -------------------------------------- Roadmap Section -------------------------------------- */
.roadmap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 66%;
  margin: 0 auto;
  padding: 75px 0;
}
.roadmap h3 {
  text-align: left;
}
.roadmap h4 {
  text-align: left;
}
/* -------------------------------------- Team Section -------------------------------------- */
.team {
  z-index: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 75px 0;
}
.teamInner {
  display: flex;
  justify-content: space-around;
  max-width: 66%;
}
.teamMember {
  padding: 50px;
  background: #00000024;
  backdrop-filter: blur(10px);
  margin: 10px;
  border-radius: 10px;
}
.teamMember:hover {
  box-shadow: 0 0 10px #ffffffc4;
  transform: translateY(-5px);
}
.teamMember img {
  width: 200px;
  border-radius: 50%;
  border: 2px solid #fff;
  box-shadow: 0 0 10px #00000020;
}
.teamMember h1 {
  color: #fff;
  font-weight: 600;
}
.teamMember h2 {
  font-weight: 800;
  font-size: 14px;
}
.teamMember h3 {
  font-weight: 400;
  font-size: 14px;
}
.teamMember .line {
  width: 100%;
}
/* -------------------------------------- FAQ Section -------------------------------------- */
.faq {
  padding: 75px 0;
}
.question {
  margin: 0 auto;
  width: 66%;
  border-radius: 10px;
  position: relative;
}
.question h4 {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
}
.question h1 {
  cursor: pointer;
  position: relative;
  font-size: 20px;
  padding: 5px;
}
.question h2 {
  font-size: 14px;
  color: #fff;
  font-weight: 100;
  opacity: 1;
  animation: show 0.3s linear;
  animation-iteration-count: 1;
  padding: 10px;
}
.line {
  width: 66%;
  margin: auto;
  border-bottom: 1px solid #fff;
  margin: 10px auto;
}
/* -------------------------------------- Footer -------------------------------------- */
.footer {
  margin-top: 75px;
  height: 100px;
  background: #000;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer h1 {
  font-size: 14px;
  font-weight: 400;
}

/* -------------------------------------- Misc Global -------------------------------------- */
.body {
  position: relative;
  z-index: 1;
}
.title {
  text-align: center;
  font-size: 45px;
  padding: 0 0 20px;
}
.subTitle {
  font-size: 16px;
}
.clouds {
  position: absolute;
  background: url('../../../public/static/images/login/clouds.png');
  animation: clouds 20s linear infinite;
  opacity: 0.2;
  height: 100%;
  max-width: 200%;
  z-index: 1;
}
li {
  text-align: left;
  font-family: "Oswald";
}
.loading {
  margin: auto;
  height: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: rotate 1.5s linear infinite;
}
@keyframes clouds {
  from {
    left: -100vw;
  }
  to {
    left: 0vw;
  }
}
@keyframes show {
  from {
    padding: 0px;
    margin: -10px;
    opacity: 0;
  }
  to {
    padding: 10px;
    opacity: 1;
  }
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.swiper-container {
  width: 750px;
  padding-top: 50px;
  padding-bottom: 50px;
}
.swiper-slide {
  width: 300px;
}
