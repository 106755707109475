@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap");

html,
body {
  width: 100%;
  min-width: 350px;
  margin: 0;
  padding: 0;
}

#root {
  width: 100%;
  padding: 0;
  margin: 4rem 0 0 0;
}

.nav-left {
  margin-right: auto !important;
}
.nav-center {
  margin-left: auto !important;
  margin-right: auto !important;
}

.nav-right {
  margin-left: auto !important;
}

.login-form {
  margin-top: 1.5rem;
}
.visible {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s, opacity 0.5s ease-out;
}

.error_msg {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s ease-in;
}



/* .leftButton {
  z-index: 999;
  position: absolute;
  top: 2.5em;
  left: 0;
} */

/* .rightButton {
  z-index: 999;
  position: absolute;
  top: 2.5em;
  right: 0;
} */

.titleFixed {
  background: "#ffffff";
  width: "100%";
  position: "fixed";
  z-index: 990;
  padding: 18 0;
}

.statusDoghnutWrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
  display: grid;
  place-items: center;
  text-align: center;
}

.statusTextWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 4% 25% 0 25%;
  height: 96%;
  display: grid;
  place-items: center;
  text-align: center;
  background-color: #ffffff;
}

.statusTextNumber {
  clear: both;
  margin: 0;
  padding: 0;
  font-size: calc(1rem + 1.5vw);
  font-weight: bold;
}

.statusTextText {
  clear: both;
  margin: 0;
  padding: 0;
  font-size: 1.3vw;
  font-weight: normal;
}

#chartWrapper {
  place-items: center;
  text-align: center;
  display: grid;
  height: 92.5%;
  max-width: 600px;
  margin: 0 auto;
}
.form-group {
  margin-bottom: 3em;
}
.iDoContainerWrapper {
  overflow: hidden;
}
.iDoContainer {
  margin: -5.7em 1em 1.5em 1em;
  width: 100%;
  background: linear-gradient(to top right, #003d6d, #ffffff 50%);
  background-position: 1em 0.5em;
  background-size: calc(100% - 3.75em) calc(100% + 3.5em);
  background-repeat: no-repeat;
  overflow: hidden;
}

.iDoContainerHover {
  padding: 0 2em 1em 2em;
  width: 100%;
  background: linear-gradient(to top right, #38679c, #38679c);
  background-size: 100% calc(100% - 0.5em);
  background-position: 1.5em 0em;
  background-repeat: no-repeat;
}

.iDoHorizontalText {
  margin: 0;
  padding: 0;
  color: #ffffff;
  position: relative;
  left: 16.5em;
  top: 44.75em;
}

.iDoVerticalText {
  margin: 0;
  padding: 0;
  color: #ffffff;
  position: relative;
  left: -18.2em;
  top: 5.75em;
  transform: rotate(-90);
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
}

.iDoArrowRight {
  width: 2em;
  height: 2em;
  border: 1em solid transparent;
  border-left-color: #ffffff;
  position: relative;
  top: 39em;
  left: 37em;
}

.iDoArrowUp {
  width: 2em;
  height: 2em;
  border: 1em solid transparent;
  border-bottom-color: #ffffff;
  position: relative;
  top: -3em;
  left: 1.22em;
}
.iDoWrapper {
  float: right;
  margin: 0;
  padding: 0;
  width: 20em;
  height: 200%;
  background-color: #38679c;
  overflow: "hidden";
}
.iDoDrawer {
  padding: 0;
  margin: 0;
  width: 100%;
  float: right;
}

/* For Doughnut */
.container_row{
  display: flex;
}

.layer1 {
  width: 100%;
  /* background-color: rgba(255,0,0,0.5);  red */
}

.layer2{
  width: 100%;
  margin-left: -100%;
  /* background-color: rgba(0,0,255,0.5); */
  /* blue */
}


@media only screen and (max-width: 650px) {
  #root {
    margin: 4rem 0 4rem 0;
  }
}
@media only screen and (max-width: 959px) {
  .iDoDrawer {
    width: 75%;
  }
  .iDoWrapper {
    max-width: 25em;
    min-width: 10em;
  }
  .iDoContainer {
    margin-top: -1.5em;
  }
  .iDoContainerHover {
    padding: 0 0 1em 2em;
    width: 100%;
    background: linear-gradient(to top right, #38679c, #38679c);
    background-size: 100% 100%;
    background-position: 1.5em 0em;
    background-repeat: no-repeat;
  }

  .iDoHorizontalText {
    display: none;
  }

  .iDoVerticalText {
    display: none;
  }

  .iDoArrowRight {
    display: none;
  }
  .iDoArrowUp {
    top: -0.75em;
  }
}
