/* Hide scrollbars in WebKit browsers (Chrome, Safari) */
.scrollable-container::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbars in Firefox */
  .scrollable-container {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE and Edge */
  }
  
  /* Optional: Apply smooth scrolling if needed */
  .scrollable-container {
    -webkit-overflow-scrolling: touch;
  }
  