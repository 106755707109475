.signup_container {
    width: 100%;
    min-height: 100vh;
    /* background-color: #f5f5f5; */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .signup_form_container {
    /* width: 100%;
      height: 150%;
      max-width: 900px;
      max-height: 500px; */
    width: 900px;
    height: 500px;
    display: flex;
    border-radius: 10px;
    /* box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
          0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%); */
  }
  
  .left {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #3bb19b;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  
  .left h1 {
    margin-top: 0;
    color: white;
    font-size: 35px;
    align-self: center;
  }
  
  .right {
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  
  .form_container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .form_container h1 {
    font-size: 40px;
    margin-top: 0;
  }
  
  .input {
    outline: none;
    border: none;
    width: 100%;
    padding: 15px;
    border-radius: 10px;
    background-color: #d7e5f8;
    margin: 5px 0;
    font-size: 14px;
  }
  
  .error_msg,
  .success_msg {
    outline: none;
    border: none;
    width: 100%;
    padding: 15px;
    border-radius: 10px;
    height: 47px;
    font-size: 14px;
    background-color: #f34646;
    color: white;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
  }
  
  .success_msg {
    background-color: green;
  }
  
  .visible {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s, opacity 0.5s ease-out;
  }
  
  .white_btn,
  .green_btn {
    border: none;
    outline: none;
    /* padding: 12px 0; */
    background-color: white;
    border-radius: 20px;
    /* width: 180px; */
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
  }
  .disabledButton {
    background-color: lightcoral;
    color: #a31a1a;
  }
  
  .green_btn {
    background-color: #3bb19b;
    color: white;
    margin: 10px;
  }
  