.input {
  outline: none;
  border: none;
  width: 100%;
  padding: 15px;
  border-radius: 10px;
  background-color: #d7e5f8;
  margin: 5px 0;
  font-size: 14px;
}

.error_msg {
  outline: none;
  border: none;
  width: 100%;
  padding: 15px;
  border-radius: 10px;
  height: 47px;
  font-size: 14px;
  background-color: #f34646;
  color: white;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.visible {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s, opacity 0.5s ease-out;
}